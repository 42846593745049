import React from 'react';

let now = new Date()
let year = now.getFullYear();
var site_copyright = `ilearning.top ©${year} Created by 八宝糖`


export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            'https://files.ilearning.top/logos/www_logo1.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个无聊的同学分享一些不知道有没有用知识的网站',
        },
        button: { className: 'banner1-button', children: '' },
      }
    ],
  },
};


export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://files.ilearning.top/logos/www_logo3.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '一个无聊的同学分享一些不知道有没有用知识的网站',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>相关项目</p>
            </span>
          ),
        },
        childWrapper: {
          children: [],
        },
      },
      // 用于占位
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '' },
        childWrapper: {
          children: [
            { href: '', name: '', children: '' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于作者' },
        childWrapper: {
          children: [
            { href: 'https://blog.ilearning.top/#/about', target: "_blank", name: 'link0', children: '联系作者' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        { site_copyright }
      </span>
    ),
  },
};
