/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner1 from './Banner1';
import Footer1 from './Footer1';

import {
  Banner10DataSource,
  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      siteCopyRight: "",
      projects: []
    };
  }


  componentDidMount () {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */

    // 获取版权信息
    fetch('https://blog.ilearning.top/api/v1/copyright')
      .then(response => response.json())
      .then(data => {
        // 处理接收到的数据
        this.setState({ siteCopyRight: data.data })
      })
      .catch(error => {
        // 处理错误
        console.error(error);
      });

    //获取项目信息
    fetch('https://blog.ilearning.top/api/v1/settings/setting')
      .then(response => response.json())
      .then(data => {
        // 处理接收到的数据
        let projects_list = JSON.parse(data.data.projects)
        let data_list = []
        projects_list.forEach(element => {
          const regex = /<a href="(.*?)" target="_blank">(.*?)<\/a>/;
          const matches = element.match(regex)
          if (matches) {
            const [fullmatch, href, text] = matches
            data_list.push({ href: href, text: text, fullmatch: fullmatch })
          } else {
            data_list.push({ href: '#', text: element, fullmatch: '' })
          }
        });
        this.setState({ projects: data_list })
      })
      .catch(error => {
        // 处理错误
        console.error(error);
      });

  }

  render () {
    const { siteCopyRight, projects } = this.state
    Footer10DataSource.block.children[1].childWrapper.children = []
    Footer10DataSource.copyright.children = siteCopyRight;
    let count = 0
    projects.forEach(element => {
      if (element.href !== "#") {
        Footer10DataSource.block.children[1].childWrapper.children.push(
          {
            name: `index${count}`,
            href: element.href,
            target: "_blank",
            children: (
              <span>
                <span>
                  <span>
                    <p>{ element.text }</p>
                  </span>
                </span>
              </span>
            ),
          }
        )
      } else (
        Footer10DataSource.block.children[1].childWrapper.children.push(
          {
            name: `index${count}`,
            href: element.href,
            children: (
              <span>
                <span>
                  <span>
                    <p>{ element.text }</p>
                  </span>
                </span>
              </span>
            ),
          }
        )
      )
      count = count + 1;

    })
    const children = [
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={ Banner10DataSource }
        isMobile={ this.state.isMobile }
      />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={ Footer10DataSource }
        isMobile={ this.state.isMobile }
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={ (d) => {
          this.dom = d;
        } }
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */ }
        { this.state.show && children }
        {/* 如果不是 dva 2.0 替换成 {children} end */ }
      </div>
    );
  }
}
